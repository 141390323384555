
import { BleClient } from '@capacitor-community/bluetooth-le';

export default {
  async init () {
    try {
      console.log('BleClient init');
      return await BleClient.initialize();
    } catch (error) {
      console.error(error);
    }
  },

  async isEnabled () {
    try {
      console.log('BleClient isEnabled');
      return await BleClient.isEnabled()
    } catch (error) {
      console.error(error);
    }
  },

  async onEnabled (callback) {
    try {
      console.log('BleClient onEnabled');
      await BleClient.startEnabledNotifications(callback);
    } catch (error) {
      console.error(error);
    }
  },
      
  async scan (toggle, resolve, reject=null, stop=null, timeout=10000) {
    if (toggle) {
      try {
        console.log('BleClient requestLEScan...');
        await BleClient.requestLEScan(
          {services: []},
          resolve
        );
  
        setTimeout(async () => {
          await BleClient.stopLEScan();
          if (!!stop) stop();
          console.log('BleClient stopped scanning.');
        }, timeout);
      } catch (error) {
        console.log('BleClient error:', error);
        if (!!reject) reject(error);
        if (!!stop) stop();
        console.warn(error);
      }
    }else{
      try {
        await BleClient.stopLEScan();
        console.log('BleClient stopped scanning.');
      } catch (error) {
        console.error(error);
      }
    }
  },
}