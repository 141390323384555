<template>
  <v-card
    ref="buzzer"
    class="buzzer rounded-lg"
    :class="{ active: buzzer.toggle, alert: window>0 }"
    @click="click"
  >
    <div class="card-background rounded-lg" />
    <v-progress-linear
      :active="loading"
      indeterminate
      absolute
      top
      height="2"
    />
    <div class="content pt-4 pb-2">
      <div class="header d-flex px-5 mb-3 align-center justify-space-between">
        <div 
          class="label text-overline mb-0 mr-auto"
        >
          Buzzer
        </div>
        <span class="text-caption mr-2">
          {{ display.title }}
        </span>
        <icon-base
          width="32"
          height="32"
          name="Buzzer"
          :color="display.color"
          class="icon mr-n1"
        >
          <buzzer-icon />
        </icon-base>
      </div>
      <marquee-text
        :duration="display.duration"
        :paused="controller.paused"
        :key="display.key"
        class="display px-5"
      >
        <p
          ref="display-text"
          class="text text-body-2 mb-0"
          v-html="display.text" 
        />
      </marquee-text>
      <!-- <div class="control">
        <v-switch
          v-if="buzzer.status=='ON'||buzzer.status=='STB'"
          v-model="buzzer.toggle"
          :loading="buzzer.loading"
          readonly
          inset
          dense
          class="mr-n3"
          color="primary"
          @click.stop="toggleStatus(!buzzer.toggle)"
        />
        <v-icon
          v-else-if="buzzer.status=='NDF'&&!loading"
          class="icon mt-4 text--secondary"
          v-text="icons.wifi" 
        />
      </div> -->
    </div>
    <div class="footer pa-2 pt-0 d-flex">
      <v-btn
        text
        small
        height="36"
        color="grey lighten-1"
        class="btn-report"
        @click.stop="toggleHelp('buzzer')"
      >
        Reportar Problema
      </v-btn>
      <v-spacer />
      <v-btn
        icon
        color="grey lighten-1"
        class="btn-help mr-1"
        @click.stop="toggleHelp('manual')"
      >
        <v-icon
          small
          class="icon"
          v-text="icons.help"
        />
      </v-btn>
    </div>
  </v-card>
</template>

<style scoped lang="scss">
  
  .buzzer {
    overflow: hidden;
    // background: radial-gradient(177.78% 100% at 0% 0%, rgba(251, 87, 115, 0.08) 0%, rgba(80, 114, 226, 0.008) 75%), var(--v-background-base);
    &.active .card-background {
      opacity: .36 !important;
      background: linear-gradient(200deg, var(--v-secondary-lighten1) 0%, var(--v-secondary-base) 200%), var(--v-background-base);
    }
    .card-background {
      opacity: .08 !important;
      background: linear-gradient(200deg, var(--v-error-base) 0%, var(--v-secondary-base) 200%), var(--v-background-base);
      will-change: background;
    }
    .content .display {
      max-width: calc(100vw - 32px);
      .text {
        padding-right: 50vw;
      }
    }
    .text {
      opacity: .8;
    }
    .icon {
      width: 32px;
    }

    a {
      color: var(--v-accent-base) !important;
    }
    
    &::before {
      opacity: 0 !important;
    }
  }

</style>

<script>

  import { 
    mdiHelpCircleOutline,
    mdiWifiOff,
    mdiHome,
    mdiCircleMedium,
    mdiClose,
  } from '@mdi/js';
  import BluetoothService from '@/bluetooth'
  import MarqueeText from 'vue-marquee-text-component'
  const md5 = require('md5');

  export default {

    components: {
      IconBase: () => import('@/components/IconBase'),
      BuzzerIcon: () => import('@/components/icons/BuzzerIcon'),
      MarqueeText
    },

    props: {
      buzzer: {
        type: Object,
        default: () => null
      },
      active: {
        type: Boolean,
        default: true
      },
      installed: {
        type: Boolean,
        default: true
      },
    },

    data: () => ({
      icons: {
        help: mdiHelpCircleOutline,
        wifi: mdiWifiOff,
        home: mdiHome,
        dot: mdiCircleMedium,
        close: mdiClose,
      },
      copy: {
        ON: {
          title: 'Ligado',
          color: 'success',
          text: 'Você está contribuindo para a Parceria. 👍',
          // goal: 'Você garantiu sua renda extra! 👏💰 Ainda assim, mantenha o seu Buzzer anunciando enquanto estiver rodando. 🤝',
          cache: 'Buzzer enviando horas acumuladas...',
        },
        NDF: {
          title: 'Sem conexão',
          color: 'grey',
          text: 'Toque aqui para se conectar diretamente ao Buzzer e atualizar seu status.',
          // goal: null,
          cache: 'Seu Buzzer possui horas armazenadas. Mantenha o equipamento ligado para permitir o envio dos dados.',
        },
        OFF: {
          title: 'Desligado',
          color: 'error',
          text: 'Está rodando? Mantenha seu Buzzer ligado. 🤝',
          // goal: 'Você garantiu sua renda extra! 👏💰 Ainda assim, você deve manter seu Buzzer anunciando enquanto estiver rodando. 🤝',
          cache: 'Seu Buzzer possui horas armazenadas. Ligue o equipamento para permitir o envio dos dados.',
        },
        STB: {
          title: 'Standby',
          color: 'error',
          text: '<b>ATENÇÃO:</b> Você NÃO está contribuindo para a Parceria!',
          // goal: null,
          cache: 'Buzzer enviando horas acumuladas...',
        },
      },
      controller: {
        init: false,
        connected: null,
        waiting: null,
        enabled: null,
        devices: [],
        device: null,
        scanning: false,
        name: null,
        uuid: null,
        paused: true
      },
      window: 0
    }),

    computed: {
      loading () {
        return this.buzzer.loading || this.controller.scanning;
      },
      display () {
        const bluetooth = this.controller;
        const status = bluetooth.connected===true ? 'ON' : this.buzzer.status in this.copy ? this.buzzer.status : 'NDF';
        let display = _.cloneDeep(this.copy[status]);
        display.text = this.buzzer.cached>480 && display.cache!=null ? display.cache : display.text;

        if (bluetooth.scanning) {
          display.title = 'Buzzer';
          display.text = 'Buscando sinal do equipamento...';
        }else{
          // TODO
        }

        if (!this.installed&&status=='NDF') {
          display.text = 'Caso esteja anunciando, suas horas ficam armazenadas. Se não estiver anunciando, reporte o problema no botão abaixo.';
        }

        display.key = md5(display.text);
        display.duration = (display.text.length / 20) + 10;
        
        return display;
      },
      // hasReachedGoal () {
      //   return !!this.goal && this.goal.hours.completed >= this.goal.hours.total;
      // },
    },

    watch: {
      active: {
        immediate: true,
        handler (active) {
          if (active) {
            const enabled = this.controller.enabled;
            if (enabled===null) {
              this.init();
            }else{
              if (this.buzzer.status!='ON') this.scan(true);
            }
          }
        }
      },
      buzzer: {
        immediate: true,
        deep: true,
        handler (buzzer) {
          if (!!buzzer.code) {
            // console.log('Buzzer status:', JSON.stringify(buzzer));
            const ble_name = 'MOBEES TRK - '+buzzer.code.substring(buzzer.code.length-6).toUpperCase();
            this.controller.name = ble_name;
            if (this.controller.waiting) {
              this.scan(true);
            }
            this.controller.waiting = false;
          }
        }
      },
      display: {
        immediate: true,
        deep: true,
        handler (display) {
          this.$nextTick(() => {
            this.toggleSlider();
          });
        }
      }
    },

    methods: {
      click () {
        if (!!this.controller.name) this.scan(true);
      },

      init () {
        if (!this.installed) return;
        BluetoothService.init().then(() => {
          this.controller.init = true;
          BluetoothService.onEnabled(this.onEnabled);
          BluetoothService.isEnabled().then((enabled) => {
            this.onEnabled(enabled);
          });
        });
      },

      scan (b) {
        if (!this.controller.init) return;
        b = b ? !this.controller.scanning : b;
        if (b) {
          this.controller.scanning = true;
          this.controller.devices = [];
          BluetoothService.scan(true, device => {
            console.log('BleClient scanned device:', JSON.stringify(device));
            this.controller.devices.push(device);
            this.connected();
          }, error => {
            this.controller.enabled = false;
          }, () => {
            this.controller.scanning = false;
            this.connected();
          }, 30000);
        }else{
          this.controller.scanning = false;
          this.buzzer.loading = false;
          BluetoothService.scan(false);
          if (!this.controller.connected) this.get();
        }
      },

      connected () {
        const devices = this.controller.devices;
        const name = this.controller.name;
        const device = _.find(devices, device => {
          return !_.isNil(device.localName) && device.localName.toUpperCase()==name.toUpperCase();
        });
        const connected = !_.isNil(device);
        this.controller.connected = connected;
        if (connected) {
          this.controller.device = device;
          this.onConnect(true, device.localName);
          this.scan(false);
        }
      },

      onEnabled (b) {
        this.controller.enabled = b;
        if (b) {
          if (!!this.controller.name) {
            this.scan(true);
          }else{
            this.controller.waiting = true;
          }
        }else{
          this.alert(true, 'Habilite o Bluetooth para conexão com o Buzzer');
        }
      },

      onConnect (b, device=null) {
        console.log('Buzzer Ble found', b, device);
        this.$emit('connect', b);
      },

      toggleStatus (b) {
        this.$emit('toggle-standby', b);
      },

      toggleHelp (section, item) {
        this.$emit('help', { section, item });
      },

      toggleSlider () {
        if (_.has(this.$refs, 'buzzer')&&_.has(this.$refs, 'display-text')) {
          const display = this.$refs['buzzer'].clientWidth;
          const text = this.$refs['display-text'].clientWidth;
          const fit = text + 40 < display;
          this.controller.paused = fit;
        }else{
          setTimeout(($) => {
            $.toggleSlider();
          }, 250, this);
        }
      },

      get () {
        this.$emit('get');
      },

      alert (b, text, timeout) {
        this.$emit('alert', b, text, timeout);
      }
    },

    mounted () {
      
    }
  }
</script>